( function( ) {
	var triger = $('span.menu-trigger'),
		themenu = $('ul.top-main-menu');
	triger.on('click', function(e) {
		themenu.stop().slideToggle();
	});
	$( window ).resize(function() {
		var ancho = $(this).width();
		if(ancho > 767) {
			themenu.css('display', 'block');
		} else {
			themenu.css('display', 'none');
		}
	});
	$(document).ready(function () {
	    if (document.cookie.indexOf("ModalShown=true")<0) {
		    $('#safe').modaal({
		        content_source: '#safe',
		        loading_content: 'Loading content, please wait.',
		        background: 'rgba(26, 26, 26, 0.8)',
		        start_open: true
		    });
		    document.cookie = "ModalShown=true;path=/";
		}
	});
    $( '#custom-close' ).click(function() {
  		$('#safe').modaal('close');
	});
}) ( );